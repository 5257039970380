<template>
  <v-app>
    <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="ml-5">
        <v-btn
          href="javascript:window.print()"
          class="title elevation-0 mr-2"
          color="grey darken-1"
          x-large
        >
          <v-icon>mdi-printer</v-icon>&nbsp; พิมพ์
        </v-btn>
        <v-btn
          @click.stop="Export2Doc('exportContent', 'word-content-1-4')"
          class="title elevation-0"
          color="grey darken-1"
          x-large
        >
          <v-icon>mdi-file-word</v-icon>&nbsp; Export to Word
        </v-btn>
      </v-toolbar-items>
    </v-system-bar>

    <div id="exportContent">
      <div class="page">
        <div align="center">
          <img
            width="150"
            height="150"
            src="ovec.png"
          />
          <br />
          <div class="head">
            เอกสารประกอบการพิจารณาคำร้องขอย้าย
          </div>
          <div class="head">
            ข้าราชการครูและบุคลากรทางการศึกษา
          </div>
          <div class="head">
            ตำแหน่ง ครู สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
          </div>

          <div class="head pa-3">
            ครั้งที่
            {{
              transference_personnels.time_ss +
                "/" +
                transference_personnels.year_ss
            }}
          </div>
          <div>
            เอกสารชุดที่ 2 เอกสารประกอบการพิจารณาการย้ายองค์ประกอบที่ 7 (7.2)
          </div>
          <br />
          <div class="pa-5">
            <img
              v-if="transference_personnels.personnel_temporary_pic"
              width="300"
              height="300"
              style="border-radius: 8px"
              :src="
                '/HRvecfiles/' + transference_personnels.personnel_temporary_pic
              "
            />
            <img
              v-else
              width="200"
              src="/HRvecfiles/blank-human-image.png"
              style="border-radius: 30px"
            />
          </div>
          <div class="head">
            ชื่อ
            {{
              transference_personnels.title_s +
                transference_personnels.frist_name
            }}
            นามสกุล
            {{ transference_personnels.last_name }}
          </div>
          <div class="head">
            เลขที่ตำแหน่ง {{ transference_personnels.id_position }}
          </div>
          <div class="head">
            วิทยฐานะ {{ transference_personnels.rang_name || "-" }}
          </div>

          <br />
          <br />
          <div class="head">
            {{ transference_personnels.college_name }} จังหวัด{{
              transference_personnels.province_name
            }}
          </div>

          <div v-if="transference_personnels.collegeVei !== '0'">
            <div class="head">
              สถาบันอาชีวศึกษา{{ transference_personnels.veiVocalName }}
            </div>
          </div>
        </div>

        <!-- New Page -->
        <p style="page-break-before: always;">&nbsp;</p>
        <!-- 2 -->

        <div class="bold16">
          7.2 การแสดงความรู้
          ความสามารถในการพัฒนาการจัดการเรียนการสอนตรงตามวิชาเอก/หรือตรงกับความต้องการของสถานศึกษาที่รับย้าย
        </div>
        <br />

        <div>
          1.ประเด็นท้าทาย <br />
          2.วิธีดำเนินการ <br />
          3.ผลที่คาดหวัง <br />
        </div>
        <br />
        <br />
        <br />
        <br />

        <br />
        <table class="text_j" width="100%" align="center">
          <tr>
            <td align="center" width="50%">
              <div class="regular16">
                <span class="regular16"
                  >ลงชื่อ...................................................ผู้ขอย้าย</span
                >
              </div>
            </td>

            <td align="center" width="50%">
              <div class="regular16">
                <span class="regular16"
                  >ลงชื่อ...............................................................ผู้รับรอง</span
                >
              </div>
            </td>
          </tr>
          <tr>
            <td align="center">
              <div class="center regular16">
                (  {{
              transference_personnels.title_s +
                transference_personnels.frist_name
            }}  {{ transference_personnels.last_name }})
              </div>
            </td>
            <td align="center">
              <div class="center regular16">
                (........................................................................)
              </div>
            </td>
          </tr>
          <tr>
            <td align="center">
              <div class="regular16">
                <span class="regular16">ตำแหน่ง {{ transference_personnels.position_name }}</span>
              </div>
            </td>
            <td align="center">
              <div class="regular16">
                <span class="regular16"
                  >ตำแหน่ง...........................................................</span
                >
              </div>
            </td>
          </tr>

          <tr>
            <td class="text-center">{{ user.college_name }}</td>
            <td></td>
          </tr>
          <tr>
            <td class="text-center">
              <div>
            {{ transference_personnels.college_name }}
          </div>
              <div v-if="transference_personnels.collegeVei !== '0'">
          
              สถาบันอาชีวศึกษา{{ transference_personnels.veiVocalName }}
          
          </div></td>
            <td></td>
          </tr>
        </table>

        <!-- New Page -->
        <p style="page-break-before: always;">&nbsp;</p>
        <!-- 2 -->
        <div class="text_j">
          <span class="font-weight-bold">หมายเหตุ</span> รูปแบบเป็นไปตามที่
          ก.ค.ศ. กำหนด ในการเขียนข้อตกลงที่เป็นประเด็นท้าทาย ตามวิทยฐานะ
          ของผู้ขอย้าย
          (ตามหลักเกณฑ์และวิธีการประเมินตำแหน่งและวิทยฐานะข้าราชการครูและบุคลากรทางการศึกษา
          ตำแหน่งครู ตามหนังสือสำนักงาน ก.ค.ศ ที่ ศธ 0206.3/ว 9 ลงวันที่ 24
          พฤษภาคม 2564) ทั้งนี้ โดยให้ผู้ประสงค์ขอย้ายและผู้อำนวยการสถานศึกษา
          หรือผู้รักษาการในตำแหน่งผู้อำนวยการสถานศึกษาต้นสังกัด
          ลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารทุกฉบับ
          <br />
          <span class="font-weight-bold">
            <u>
              โดยเอกสารจัดทำในโปรแกรม Microsoft Office Word ความยาวไม่เกิน 5
              หน้า กระดาษ A4 แบบอักษร TH SarabunIT๙ หรือ THSarabunPSK
              ขนาดตัวอักษร 16 พอยท์ 
            </u></span
          >
          <br />
          <div class="font-weight-bold">
            (กรรมการจะพิจารณาเอกสารเฉพาะหน้าที่ 1 – 5 เท่านั้น)
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    user: [],
    loading: true,
    periods: [],
    transference_personnels:[],
  }),

  async mounted() {
    let result_user;
    result_user = await this.$http.post("personnel_temporary.php", {
      ApiKey: this.ApiKey,
      id_card: this.url_result
    });
    this.user = result_user.data;

    await this.periodQuery();
    await this.transference_personnelsQueryAll();
    
  },

  methods: {
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable: "1",
        period_type: "teacher"
      });
      this.periods = result_period.data;
      ;
    },

    async transference_personnelsQueryAll() {
      let result = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: this.url_result
      });
      this.transference_personnels = result.data;
    },




    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-16'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-16," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    url_result() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];
    },

    marital_status() {
      let marital_status = this.transference_personnels.marital_status;
      let marital_result;
      if (marital_status == "single") {
        marital_result = "โสด";
      } else if (marital_status == "married") {
        marital_result = "สมรส";
      } else if (marital_status == "widow") {
        marital_result = "หม้าย";
      } else if (marital_status == "divorce") {
        marital_result = "หย่า";
      } else if (marital_status == "separate") {
        marital_result = "แยกกันอยู่";
      }
      return marital_result;
    },

    date_today_cal() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },

    get_gov_Age() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(this.transference_personnels.ser_date);
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years + " ปี " + months + " เดือน " + days + " วัน";
      return age;
    }
  },
  props: {
    source: String
  }
};
</script>
<style>
body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}
u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}
.text_l {
  border-collapse: collapse;
  text-align: left;
  text-justify: inter-word;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 21cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1.5cm;
  padding-right: 1.5cm;
  padding-bottom: 1.5cm;
  padding-left: 2cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 22pt;
  font-weight: bold;
  line-height: 1.5;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
/*.subpage {
      height: 247mm;
    }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1cm;
    padding-right: 1cm;
    padding-bottom: 1cm;
    padding-left: 1.5cm;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
        font-size: 18px;
        font-weight: bold;
      }
      .regular12 {
        font-size: 12px;
      }
      .blod12 {
        font-size: 12px;
        font-weight: bold;
      }
      .blackRegula8 {
        font-size: 8px;
      } */
  .noprint {
    display: none;
  }

  .gap-10 {
    width: 100%;
    height: 10px;
  }
  .gap-20 {
    width: 100%;
    height: 20px;
  }
  .gap-30 {
    width: 100%;
    height: 30px;
  }
}
</style>
